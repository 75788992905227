import Image from 'next/image'
import { ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import styles from './affiliate.module.scss'
import { Logo } from '@/components/styleguide/Card/Affiliate/Logo'
import { constants } from '@/lib/constants'
import { Size, Theme } from '@/types/system'

export type AffiliateCardProps = {
  partner: 'sellYourVehicle' | 'insurance'
  title: string | ReactNode
  shortenedTitle: string
  paragraph: string
  theme: Theme
  children: ReactNode
  isVans?: boolean
  shortenedParagraph: string
  href: string
  onClick?: () => void
}

export const AffiliateCard = ({
  title,
  shortenedTitle,
  paragraph,
  shortenedParagraph,
  theme = Theme.dark,
  partner,
  isVans = false,
  children,
  href,
  onClick,
}: AffiliateCardProps) => {
  const onCardClick = () => {
    if (onClick) {
      onClick()
    }
  }
  return (
    <a
      href={href}
      data-theme={theme}
      data-partner={partner}
      onClick={onCardClick}
      rel="noopener noreferrer sponsored"
      className={styles.main}>
      <div
        className={styles['header-logo']}
        data-is-visible={partner === 'sellYourVehicle'}>
        <Logo
          partner={partner}
          size={Size.Medium}
        />
      </div>
      <div className={styles.content}>
        <h2 className={`${styles.title} ${styles.short}`}>{shortenedTitle}</h2>
        <h2 className={`${styles.title} ${styles.normal}`}>{title}</h2>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className={`${styles.paragraph} ${styles.short}`}>
          {shortenedParagraph}
        </ReactMarkdown>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className={`${styles.paragraph} ${styles.normal}`}>
          {paragraph}
        </ReactMarkdown>
      </div>
      <div className={styles.cta}>{children}</div>
      {partner === 'sellYourVehicle' && (
        <div className={styles.sellYourVehicle}>
          <Image
            src={`${constants.imageHost.assets}/branding/motorway-${
              isVans ? 'van-background-yellow.jpg' : 'background-transparent.png'
            }`}
            alt="Motorway background"
            height="240"
            width={418}
          />
        </div>
      )}
      {partner === 'insurance' && (
        <div className={styles.insurance}>
          <Image
            src={`${constants.imageHost.assets}/branding/moneysupermarket-car.png`}
            alt="MoneySuperMarket car graphic"
            height="450"
            width={532}
          />
        </div>
      )}
    </a>
  )
}
